//? --------- Kooliner Detail Controller ----------- //
    function front_getCurrentTime()
    {
        var currentTime = new Date();        
        $.ajax({
            url : $('#dec_oth_comp').attr('current_url'),
            data : {
                kooliner_id : $('#dec_oth_comp').attr('kooliner_id'),
                current_time : currentTime.getHours()+':'+currentTime.getMinutes()
            },
            cache: false,
            success: function(data) {
                if(data.status == 1){
                    $('.open-time').html('<span class="open-text">BUKA</span>\
                    <span>'+data.text+'</span>');
                }else if(data.status == 0){
                    $('.open-time').html('<span class="open-text">TUTUP</span>\
                    <span>'+data.text+'</span>');
                }
            }
        });
    }